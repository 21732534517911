<script>
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'SolutionsTypesManage',
  components: {
    Radio: () => import('@/components/general/Radio'),
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    ColorPicker: () => import('@/components/general/ColorPicker'),
    IconPicker: () => import('@/components/general/IconPicker'),
    SelectField: () => import('@/components/general/SelectField'),
    ContentHeader: () => import('@/components/general/ContentHeader')
  },

  data () {
    return {
      consumeTypeOptions: [
        {
          label: this.$t('global:solution.type.option.upload'),
          value: 'upload'
        },
        {
          label: this.$t('global:solution.type.option.integration'),
          value: 'integration'
        },
        {
          label: this.$t('global:solution.type.option.url'),
          value: 'url'
        }
      ],

      consumeTypeOptionsFiltered: [],

      colorPickerOptions: [
        '#F63A87',
        '#EF4149',
        '#FDA255',
        '#FDD463',
        '#FCED83',
        '#DCF266',
        '#60D974',
        '#C7EEF8',
        '#43AED1',
        '#0274DF',
        '#904AAF',
        '#AD93D6'
      ],

      iconPickerOptions: [
        'icon-audio.svg',
        'icon-doc.svg',
        'icon-ebook.svg',
        'icon-hybrid.svg',
        'icon-monitor.svg',
        'icon-papers.svg',
        'icon-phone.svg',
        'icon-pin.svg',
        'icon-podcast.svg',
        'icon-video.svg',
        'icon-videochat.svg',
        'icon-whatsapp.svg'
      ],

      offerList: [],
      integrationsList: [],
      formData: {
        offer: null,
        name: '',
        alias: '',
        color: null,
        icon: '',
        consumeType: 'upload',
        integration: null
      },

      backUrl: { name: 'solutions.types' },
      modalConfirm: false,
      modalLeave: false,
      selectedOffer: null,
      formattedUrl: `${process.env.VUE_APP_PORTAL_URL}`,
      loadedSolutionType: null,

      blockedIntegrations: {
        upload: [
          'curso',
          'incorporado',
          'video'
        ],

        integration: [
          'arquivo',
          'audio',
          'incorporado',
          'video'
        ],

        url: [
          'arquivo',
          'curso'
        ]
      }
    }
  },

  validations () {
    return {
      formData: {
        offer: { required },
        name: { required },
        alias: { required },
        color: { required },
        consumeType: { required },

        integration: {
          required: requiredIf(function () {
            return this.formData.consumeType === 'integration'
          })
        }
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),

    isEditing () {
      return !!(this.$route.params.id || this.formData.id || false)
    },

    getConsumeTypeMessage () {
      if (this.formData.consumeType === 'url') {
        return this.$t('global:form.solution_type.consumeType.url')
      } else if (this.formData.consumeType === 'upload') {
        return this.$t('global:form.solution_type.consumeType.upload')
      } else if (this.formData.consumeType === 'integration') {
        return this.$t('global:form.solution_type.consumeType.integration')
      }

      return this.$t('global:form.solution_type.consumeType')
    },

    shouldShowConsumeTypeOptions () {
      return this.formData.offer && this.consumeTypeOptionsFiltered && this.consumeTypeOptionsFiltered.length > 0
    }
  },

  watch: {
    'formData.offer' (val) {
      this.selectedOffer = this.offerList.find(item => item.value === val)
      this.updateConsumeTypeOptions()

      if (!this.isEditing) {
        this.autoSelectConsumeType()
      }
    },

    'formData.alias' () {
      this.formattedUrl = `${process.env.VUE_APP_PORTAL_URL}${this.formData.alias}/{URL_DA_SOLUÇÃO}`
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getOfferList(),
      this.getIntegrations()
    ])
      .catch(() => {
        this.setFeedback({ message: this.$t('solution_types.load:fetch.error') })
        this.$router.push({ name: 'solutions.types' })
      })
      .finally(() => {
        if (this.isEditing) {
          this.setFetching(true)

          this.attemptManageSolutionType(this.$route.params.id)
            .then((response) => {
              this.loadedSolutionType = { ...response }
              this.formData.name = response.name
              this.formData.active = response.active
              this.formData.alias = response.alias
              this.formData.color = response.color
              this.formData.icon = response.icon
              this.formData.consumeType = response.bond
              this.formData.offer = response.Offer.id

              if (this.formData.consumeType === 'integration') {
                this.formData.integration = response.Integration.id
              }
            })
            .catch(() => {
              this.setFeedback({ message: this.$t('solution_types.load:fetch.error') })
              this.$router.push({ name: 'solutions.types' })
            })
            .finally(() => {
              this.setFetching(false)
            })
        }

        this.setFetching(false)
        this.$v.$reset()
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      // 'attemptUpdateSolution',
      // 'attemptUpdateActiveSolution',
      // 'attemptSaveActiveSolution',
      'attemptManageSolutionType',
      'attemptSaveActiveSolutionType',
      'attemptUpdateSolutionType',
      'attemptGetOfferList',
      'attemptGetIntegrations'
    ]),

    formatFormData () {
      const data = {}

      data.id_offer = this.formData.offer
      data.name = this.formData.name
      data.alias = this.formData.alias
      data.color = this.formData.color
      data.icon = this.formData.icon
      data.bond = this.formData.consumeType
      data.id_integration = this.formData.integration

      return data
    },

    submitCreation () {
      this.setFetching(true)

      this.attemptSaveActiveSolutionType({ data: this.formatFormData() })
        .then(({ data }) => {
          this.$router.push({
            name: 'solutions.types.manage',
            params: {
              id: data.id,
              isNew: 'edit'
            }
          })

          this.setFeedback({ message: this.$t('solution_types:feedback.create.success') })
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response, 'creation')

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateSolutionType({
        id: this.$route.params.id,
        data: formData
      })
        .then(({ data }) => {
          if (data.imagePath) {
            data.imagePath = data.imagePath + '?v=' + Math.random()
          }

          this.setFeedback({ message: this.$t('solution_types:feedback.update.success') })

          this.hasEvent = this.formData.hasEvent
          this.fetchConnections(data.groupSolution)
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response, 'update')

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit (next) {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
          resolve(true)
        } else {
          this.setFeedback({ message: this.$t('solutions:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    getOfferList () {
      const pagination = {
        filter: {
          active: true
        },

        order: {
          name: 'asc'
        }
      }

      return this.attemptGetOfferList(pagination)
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.offerList.push({
                text: element.name,
                value: element.id,
                id: element.id,
                alias: element.alias
              })
            })

            return true
          }
        })
    },

    getParsedErrorMessage (response, origin) {
      if (response.data.error.message === 'sas_unable_to_get_event') {
        return this.$t('solutions:feedback.sas.unable.get.event')
      }

      if (response.data.error.message.includes('solution_type_alias_already_exists')) {
        return this.$t('solution_types:feedback.alias.already.exist')
      }

      if (origin === 'creation') return this.$t('solution_types:feedback.create.error')

      if (origin === 'update') return this.$t('solution_types:feedback.update.error')
    },

    getIntegrations () {
      this.setFetching(true)
      this.integrationsList = []

      this.attemptGetIntegrations({
        limit: 3000,
        filter: {
          is_visible: 1,
          active: 1
        }
      })
        .then((response) => {
          if (response.data) {
            response.data.forEach(element => {
              this.integrationsList.push({
                text: element.name,
                alias: element.alias.toLowerCase(),
                value: element.id
              })
            })
          }

          this.setFetching(false)
        })
        .catch(() => {
          this.setFetching(false)
        })
    },

    updateConsumeTypeOptions () {
      this.consumeTypeOptionsFiltered = this.consumeTypeOptions.map((consumeTypeItem, consumeTypeIndex) => {
        if (!this.formData.offer) return consumeTypeItem
        const newConsumeTypeItem = consumeTypeItem

        newConsumeTypeItem.disabled = this.blockedIntegrations[consumeTypeItem.value].includes(this.selectedOffer.alias)
        this.$set(this.consumeTypeOptions, consumeTypeIndex, newConsumeTypeItem)

        return consumeTypeItem
      })
    },

    autoSelectConsumeType () {
      if ([
        'arquivo',
        'audio'
      ].includes(this.selectedOffer.alias)) {
        this.formData.consumeType = 'upload'
      } else if ([ 'curso' ].includes(this.selectedOffer.alias)) {
        this.formData.consumeType = 'integration'
      } else if ([
        'video',
        'audio',
        'incorporado'
      ].includes(this.selectedOffer.alias)) {
        this.formData.consumeType = 'url'
      }
    }
  }
}
</script>

<template>
  <div class="main-content solutions-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('solution_types.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.solution_types')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />

      <ActionBar slot="actionbar" />

      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit(false)"
        />
      </template>
    </ContentHeader>

    <div class="main-content-inner type-manager">
      <div class="center">
        <div class="solutions-create-header">
          <p
            class="solutions-create-description"
            v-html="$t('solution_types.create:header.description')"
          />

          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>

          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>

        <form
          class="form"
          @submit.prevent="submit"
        >
          <SelectField
            v-model="formData.offer"
            tabindex="0"
            :label="$t('global:form.offer')"
            :items="offerList"
            :validation="$v.formData.offer"
            :disabled="isEditing"
          />

          <InputField
            v-model="formData.name"
            :label="$t('global:form.solution_type.name')"
            :validation="$v.formData.name"
            :counter="20"
          />

          <InputField
            v-model="formData.alias"
            :under-description="formattedUrl"
            :label="$t('global:form.solution_type.url')"
            :validation="$v.formData.alias"
          />

          <ColorPicker
            v-model="formData.color"
            :label="$t('solution_types:manage.color')"
            :validation="$v.formData.color"
            :options="colorPickerOptions"
            class="form-item"
          />

          <IconPicker
            v-model="formData.icon"
            :label="$t('solution_types:manage.icon.description')"
            :validation="$v.formData.icon"
            :options="iconPickerOptions"
            context="/assets/images/themes/default/svg/solution_types/"
            class="form-item"
            no-icon-option-enabled
            small
          />

          <Radio
            v-show="shouldShowConsumeTypeOptions"
            v-model="formData.consumeType"
            :under-description="$t('global:form.solution_type.consumeType')"
            :items="consumeTypeOptionsFiltered"
            horizontal
            :validation="$v.formData.consumeType"
            :disabled="isEditing"
          />

          <i v-if="formData.consumeType && shouldShowConsumeTypeOptions">
            {{ getConsumeTypeMessage }}
          </i>

          <SelectField
            v-show="formData.consumeType === 'integration'"
            v-model="formData.integration"
            tabindex="0"
            :label="$t('global:form.solution_type.integration')"
            :items="integrationsList"
            :validation="$v.formData.integration"
            :disabled="isEditing"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>

<style lang="scss" src="../assets/scss/Solution.scss"></style>

<style lang="scss">
.main-content {
  padding-bottom: 120px;
}

.type-manager {

  .form {
    display: flex;
    flex-direction: column;

    i {
      padding-bottom: 24px;
    }
  }
}

.link-selection,
.link-selection .form-section {
  display: flex;
  margin: 40px auto 32px;
  flex-direction: column;
}

.link-selection .form-section+.form-section {
  margin-top: 0;
}

.link-selection .form-section {
  width: 480px;
}

.link-selection .form-section.form-section--centered {
  text-align: center;
}

.link-selection .search-field,
.link-selection .link-table>div {
  width: 50%;
}

.link-table {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.solutions-create-header+.center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}

.warning-service-integration {
  padding: 10px;
  background-color: #ffc14d;
  margin-bottom: 10px;
}

.warning-rae-configuration {
  color: #fa6666;
  font-size: 12px;
}

.pragmatic-list {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.pragmatic-tools {
  padding-right: 15px;
}

.pragmatic-tools button {
  padding: 4px;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}

.td-tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  padding: 0 10px;
  min-width: 70px;
  text-align: center;
  background: #F3F4F7;
  border-radius: 50px;
  text-transform: none;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}

.form-input-file-label-text,
.form-section-title {
  text-transform: none;
}

.btn.view-file {
  font-size: 16px;
  text-transform: none;
}

.solution-price-error-message {
  color: #ffc14d;
  font-size: 14px;
}
</style>
